<template>
  <div class="w-full">
    <div class="w-full flex items-center justify-between mt-3">
      <div class="h-px flex-1 bg-cs-light-grey dark:bg-cs-medium-grey"></div>
      <span class="text-cs-medium-grey text-xs mx-5">{{ $t('register.or') }}</span>
      <div class="h-px flex-1 bg-cs-light-grey dark:bg-cs-medium-grey"></div>
    </div>
    <div class="flex items-center justify-between mt-3">
      <NuxtLink
        :href="AUTH_SERVER_URL + '/auth/twitter'"
        class="px-5 py-2 border border-cs-light-grey dark:border-cs-medium-grey rounded-[8px] flex flex-1 mr-5 items-center justify-center"
        @click="() => executeAction(LOGIN_METHOD_TWITTER)"
      >
        <IconTwitter class="dark:hidden" />
        <IconTwitterWhite class="hidden dark:block" />
      </NuxtLink>
      <NuxtLink
        :href="AUTH_SERVER_URL + '/auth/github'"
        class="px-5 py-2 border border-cs-light-grey dark:border-cs-medium-grey rounded-[8px] flex flex-1 mr-5 items-center justify-center"
        @click="() => executeAction(LOGIN_METHOD_GITHUB)"
      >
        <IconGithub class="dark:hidden" />
        <IconGithubWhite class="hidden dark:block" />
      </NuxtLink>
      <NuxtLink
        :href="AUTH_SERVER_URL + '/auth/apple'"
        class="px-5 py-2 border border-cs-light-grey dark:border-cs-medium-grey rounded-[8px] flex flex-1 lg:mr-5 items-center justify-center"
        @click="() => executeAction(LOGIN_METHOD_APPLE)"
      >
        <IconApple class="dark:hidden" />
        <IconAppleWhite class="hidden dark:block" />
      </NuxtLink>
      <div
        class="cursor-pointer hidden lg:flex px-5 py-2 border border-cs-light-grey dark:border-cs-medium-grey rounded-[8px] lg:flex-1 items-center justify-center"
        @click="showWalletPopup"
      >
        <IconWallet class="dark:hidden" />
        <IconWalletWhite class="hidden dark:block" />
      </div>
    </div>
    <ModalInstalledWallets :method="loginWithWallet" :showModal="showWalletModal" :toggleModal="showWalletPopup" />
  </div>
</template>

<script lang="ts" setup>
import IconGithub from 'assets/icons/landing/icon-github.svg'
import IconTwitter from 'assets/icons/landing/icon-twitter.svg'
import IconWallet from 'assets/icons/landing/icon-wallet.svg'
import IconApple from 'assets/icons/landing/icon-apple.svg'
import IconGithubWhite from 'assets/icons/landing/icon-github-white.svg'
import IconTwitterWhite from 'assets/icons/landing/icon-twitter-white.svg'
import IconWalletWhite from 'assets/icons/landing/icon-wallet-white.svg'
import IconAppleWhite from 'assets/icons/landing/icon-apple-white.svg'

import {useLoginWithWallet} from '~/composables/wallet'
import {LANGUAGE_COOKIE} from '~/constants/i18n'
import {getAuthServerUrl} from '~/api'
import {
  GTM_EVENT_GUEST_MODAL_LOGIN_APPLE,
  GTM_EVENT_GUEST_MODAL_LOGIN_GITHUB,
  GTM_EVENT_GUEST_MODAL_LOGIN_TWITTER,
  GTM_EVENT_GUEST_MODAL_LOGIN_WALLET,
  GTM_EVENT_GUEST_MODAL_REGISTER_APPLE,
  GTM_EVENT_GUEST_MODAL_REGISTER_GITHUB,
  GTM_EVENT_GUEST_MODAL_REGISTER_TWITTER,
  GTM_EVENT_GUEST_MODAL_REGISTER_WALLET,
  GTM_EVENT_TYPE_SUBMIT
} from '~/constants/gtmEvent'
import {LOGIN_METHOD_APPLE, LOGIN_METHOD_GITHUB, LOGIN_METHOD_TWITTER, LOGIN_METHOD_WALLET} from '~/constants/common'

interface ComponentProps {
  isModal?: boolean
  isLogin?: boolean
}

const props = defineProps<ComponentProps>()

const {locale} = useI18n()
const {$eventTracker, $showErrorToast} = useNuxtApp()
const languageCookie = useCookie(LANGUAGE_COOKIE)

const showWalletModal = ref<boolean>(false)

const AUTH_SERVER_URL = getAuthServerUrl()

const showWalletPopup = () => {
  showWalletModal.value = !showWalletModal.value
  if (showWalletModal.value) {
    executeAction(LOGIN_METHOD_WALLET)
  }
}

const loginWithWallet = async (userAddress: string, signature: WalletSignature, walletName: string) => {
  const {error} = await useLoginWithWallet(userAddress, signature, walletName, locale, languageCookie, $eventTracker)
  if (error) {
    $showErrorToast(error)
  } else {
    navigateTo('/')
  }
}

// Send GTM events
const executeAction = (source: string) => {
  if (props.isModal) {
    const eventName = getEventName(source, props.isLogin)
    $eventTracker.triggerEvent(eventName, GTM_EVENT_TYPE_SUBMIT)
  }
}

const getEventName = (source: string, isLogin: boolean) => {
  if (isLogin) {
    switch (source) {
      case LOGIN_METHOD_TWITTER:
        return GTM_EVENT_GUEST_MODAL_LOGIN_TWITTER
      case LOGIN_METHOD_GITHUB:
        return GTM_EVENT_GUEST_MODAL_LOGIN_GITHUB
      case LOGIN_METHOD_APPLE:
        return GTM_EVENT_GUEST_MODAL_LOGIN_APPLE
      case LOGIN_METHOD_WALLET:
        return GTM_EVENT_GUEST_MODAL_LOGIN_WALLET
    }
  } else {
    switch (source) {
      case LOGIN_METHOD_TWITTER:
        return GTM_EVENT_GUEST_MODAL_REGISTER_TWITTER
      case LOGIN_METHOD_GITHUB:
        return GTM_EVENT_GUEST_MODAL_REGISTER_GITHUB
      case LOGIN_METHOD_APPLE:
        return GTM_EVENT_GUEST_MODAL_REGISTER_APPLE
      case LOGIN_METHOD_WALLET:
        return GTM_EVENT_GUEST_MODAL_REGISTER_WALLET
    }
  }
}
</script>
